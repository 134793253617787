.pointer {
  cursor: pointer;
}

.pass-list {
  list-style-image: url('../images/icons8-close.svg');
  width: 10;
  height: 10;
}

.pass-list li::marker {
  font-size: 0.5rem;
  /* You can use px, but I think rem is more respecful */
  height: 50px;
}

.passtick-list {
  list-style-image: url('../images/icons8-done.svg');
  width: 10;
  height: 10;
}

.passtick-list li::marker {
  font-size: 0.5rem;
  /* You can use px, but I think rem is more respecful */
}

.sidemenu-items .accordion-button {
  background: #ffffff;
  box-shadow: none;
  border: 0;

}

.sidemenu-items .accordion-button:active {
  background: #f6f6f6;
}

.bs-none {
  border: 'none'
}


.login-wrp .input-group .input-group-text {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  background: transparent;
  border: 0;
}

.badge-style {
  background-color: #d6d5d5 !important;
  font-size: 15px;
  font-weight: 500;
  color: #000;
}

.react-tel-input .form-control {
  height: 46px !important;
  width: 100% !important;
}

.react-tel-input :disabled {
  cursor: not-allowed;
  background: #e9ecef !important;
}

.dropzone-input {
  border: 1px solid #babfcb;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background: #d6d5d5;
}

.cursor-pointer {
  cursor: pointer;
}

.menu-arrow {
  position: absolute;
  right: 0;
  width: 13px !important;
  height: 13px !important;
  transition: all ease 0.25s;
}

.leftside-bar .sidebar-menu .sidemenu-items .collapsed-after .menu-arrow {
  transform: rotate(-90deg);
}

.err {
  min-height: 25px;
  display: block;
}

.spin {
  margin-top: 25%;
}

.loader {
  width: 3rem;
  height: 3rem
}

.h-30 {
  height: 30px;
}

.boxwrapper {
  width: 100%;
  max-width: 200px;
  border-color: #000;
}

.btnyesorno {
  background-color: #fff!important;
  border-style: none;
  box-shadow: 1px 1px 5px 2px rgb(0 0 0 / 10%);
  color: #000!important;
  padding: 2px 21px;
  font-size: 16px;
}

.hint-list .menu-item {
  position: relative;
  list-style: none;
  padding: 4px 0 4px 35px;
}

.hint-list .menu-item::before {
  content: "";
  background-image: url('../images/hint.svg');
  width: 25px;
  height: 25px;
  position: absolute;
  left: 0;
  top: 2px;
  background-size: contain;
}

li.menu-item {
  margin: 0 0 10px 0;
}

.text-blue {
  color: #045C87;
}

.fileWrapper {
  border-style: dotted;
  min-height: 150px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
}

.error-border {
  border: '1px solid #dc3545 !important',
}

.activespan{
  background-color: #f6f6f6;
}

/* .user-images {
  width: 150px;
  height: 150px;
  position: relative;
  margin: auto;
}
.user-images .up-icon {
  position: absolute;
  bottom: 9px;
  right: 5px;
  background: #023047;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.user-images .up-icon svg {
  font-size: 20px;
}
.user-images img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 100%;
  border: solid 1px #efefef;
} */

.text-light-blue {
  color: #1e7ba9 !important;
}
.btn-highlight {
  box-shadow: 0 0 0 0.25rem rgba(40, 79, 99, 0.5);
}
.box-card {
    padding: 1.3rem 1rem 3rem 1rem;
    box-shadow: 0 1px 9px rgba(0,0,0,0.1);
    height: 100%;
    border: solid 1px transparent;
}
.tooltip-inner {
  text-align: justify;
  text-justify: auto;  
}
.w-40 {
  width: 40%;
  flex: 0 0 40%;
}
.w-60 {
  width: 60%;
  flex: 0 0 60%;
}
.main-collapse {
  margin-bottom: 10px;
}
.main-collapse .accordion-item {
  border: 0;
}
.main-collapse .accordion-body {
  padding: 1rem 5px;
}
.main-collapse .accordion-button {
  border-radius: 7px;
    background: #f7f8f9;
    border: solid 1px #efefef;
}
.sub-collapse .accordion-button {
  background: #fff;
}
.sub-collapse .accordion-item {
  border: 1px;
}
.permission-text {
  font-weight: 600;
  padding-left: 1rem;
}
.permission-outer .title {
  font-weight: 600;
  font-size: 23px;
  margin-bottom: 10px;
}
.topic-boxes {
  border-radius: 8px;
  background: #efefef;
  padding: 1rem 1rem 1rem 1.5rem;
}
.topic-title {
  font-size:17px;
  font-weight: 600;
}
.permission-sub {
  margin-bottom: 2rem;
}
.title-border {
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px !important;
}
.profile-det .profile-pic {
  background: #efefef;
}
.contents {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
  min-height: calc(100vh - 110px) !important;
  max-height: calc(100vh - 110px) !important;
	animation: fadeInAnimation ease 1s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
}

.manage-access-box .profile-det .profile-pic {
  width:50px;
  height: 50px;
  border-radius: 100%;
  overflow: hidden;
}
.wizard-stepcomplete{
  margin-right:3px !important
}
.text-justify {
  text-align: justify;
}
.bginfo{
  background-color: #def1e9;
  color:#023047  ;
}
.bginfolight{
  background-color: #f6eaff;
  color:#023047;
}
.permission-modal .modal-dialog {
  max-width: 95%;
}
.p-badge{
  position:absolute;
  right: 0;
  background-color: #ddcaeb !important;
  color:#7a28ba;
}
.new-tag {
  position: absolute;
  background: #ddcaeb;
  right: 6px;
  top: 6px;
  padding: 3px 7px;
  border-radius: 3px;
  font-weight: 600;
  font-size: 14px;
}

.text-height{
  height: 70px;
}
.banner-img {
  border: 4px solid #d3d3d3;
  border-radius: 25px;
}
.estatecheck{
  position: absolute;
    right: 20px;
    top: 20px;
    opacity: 0;
    visibility: hidden;
}
.estatecheckvisible{
  position: absolute;
    right: 20px;
    top: 20px;
}
.estatesetcheck{
  position: absolute;
    right: 20px;
    top: 20px;
}

.home-boxes.catgeorylist[disabled] {
  pointer-events:visible;
  opacity: 0.5;
  background-color: #f6f6f6;
  color: #000000;
}
.home-boxes.future[disabled] {
  pointer-events:visible;
  opacity: 0.5;
  background-color: #f6f6f6;
  color: #000000;
}
.home-boxes[disabled] {
  pointer-events:visible;
  color: #1e7ba9;
  background-color: #f6f6f6;
}
.home-boxes:hover .estatecheck{
  opacity: 1;
  visibility: visible;
}
.estateborder{
  border-color: #1e7ba9 !important; 
  border-width: 0.2rem;
}


  .checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 20px auto;
  box-shadow: inset 0px 0px 0px #7ac142;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}
.completebanner{
  border: solid 1px #e7e7e7;
    padding: 3rem 2rem;
    border-radius: 10px;
    max-width: 550px;
box-shadow: 0 0.125rem 1.25rem rgb(0, 0, 0, 0.07)
}
.planning{
  max-width:250px ;
  max-height: 150px;
  width: 210px;height: 150px;
}
.help{
  max-width:250px ;
  max-height: 300px;
  width: 250px;height: 120px;
}

.estateplanninglist{
  max-width: 850px;
  display: flex;
  flex-wrap: wrap;

}
a.disabled {
  pointer-events: none;
  cursor: default;
}

.searchinput{
  border: 1px solid #dc3545;
}

.searchinput::placeholder {
  color: #dc3545;
}
.btn-permission{
  background-color: #ffffff;
  border-color: #023047;
  color: #023047;
}

.general-settings .accordion-button::after {
  display: none;
}

.title-w{
  width: 100% !important;
}
.float-r{
  float: right;
}

.bg-b {
  
  background-color: #def1e9;
  color: #425475;
  text-align: center;
  margin-top: 0.2rem;
  border: none;
}

.backImg{
  background-image: url('../images/background.jpg');
}
.logo-header{
   color:#4fa5d8;
}
.logo-sub{
  color: #3b3873;
}
.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  padding: 0 0.5em;
}

.recovered-mails{
  border-bottom: 1px solid #ccc;
  padding:8px 16px ;
  display: flex;
}
.recovered-mails-after{
  display: flex;
  justify-content: center;
  border: 1px solid #ccc;
  padding: 4px 8px;
  border-radius: 10px
}
.search-color {
  color: #0f597d;
}
.search-area-boxes .search-sub-title {
  font-size: 17px;
  font-weight: 600;
}

.search-area-boxes .breadcrumb .breadcrumb-item {
  font-size: 14px;
}
.search-area-boxes .breadcrumb .breadcrumb-item a {
  color: #696969;
  text-decoration: none;
}
.search-area-boxes .search-title {
  font-size: 18px;
  font-weight: 700;
}
/* .content-area {
  padding-left: 300px;
  width: 100%;
  padding-right: 0;
  min-height: calc(100vh - 110px) !important;
  position: relative;
} */

.pricing-detsils {
  position: relative;
  min-height: 100vh;
  padding: 2rem 4rem;
}
.com-logo {
  width: 140px;
  text-align: center;
  margin: auto;
}
.plan-boxes {
  border: solid 4px #d9eefb;
  min-height: 422px;
  border-radius: 12px;
  overflow: hidden;
  padding: 1rem;
  -webkit-transition: all ease 0.25s;
  transition: all ease 0.25s;
}
.plan-boxes:hover, .plan-boxes.active {
  background: #023047;
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}
.plan-boxes:hover .titlebx, .plan-boxes.active .titlebx {
  background: #fff;
}
.plan-boxes .titlebx {
  background: #e5eaec;
  max-width: 280px;
  -webkit-transform: skew(20deg);
  transform: skew(20deg);
  padding: 12px 5px 12px 42px;
  margin-left: -30px;
}

.plan-boxes .contentarea {
  padding: 1rem;
}
.com-logo p {
  background: #023047;
  border-radius: 5px;
  color: #fff;
  font-size: 22px;
  letter-spacing: 0.5px;
  padding: 4px 16px 4px 20px;
  text-align: center;
}
.plan-boxes:hover .btn-primary, .plan-boxes.active .btn-primary {
  background: #fff;
  color: #023047;
}
.plan-boxes:hover h4, .plan-boxes:hover .h4, .plan-boxes:hover p, .plan-boxes.active h4, .plan-boxes.active .h4, .plan-boxes.active p {
  color: #fff;
}
.plan-boxes h4, .plan-boxes .h4 {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}

.pricing-detsils::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  background-image: url('../images/bg-2.png');
  width: 200px;
  height: 130px;
  background-size: cover;
}
.pricing-detsils::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  background-image: url('../images/bg-1.png');
  width: 200px;
  height: 130px;
  background-size: cover;
}

.contentarea h1{
  font-size: 40px;
    font-weight: 800;
    text-align: center;
}
.plan-boxes:hover .price  {
  color: #ffffff; /* Change this to your desired hover color */
}
.expandicons:hover{
  color: #fff;
  
}
.plan-boxes:hover .expandicons  {
  color: #ffffff; /* Change this to your desired hover color */
}
.expandicons{
  cursor: pointer;
  
}
.activeBox{ 
  transform: scale(1.05);
   color: #fff;
  background: #023047;}
  .activeBtn{ 
    background: #fff;
    color: #023047;}


    .circle {
      border-radius: 3px;
      width: 150px;
      height: 150px;
      background: black;
      position: absolute;
      right: 0px;
      top: 0;
      background-image: linear-gradient(to bottom, #6ec0f0 0%, #3b3873 100%);
      border-bottom-left-radius: 170px;
    }

    .check-payment-circle {
      background: url('../images/check-green.svg');
      width: 180px;
      height: 180px;
      margin-bottom: 26px;
    }
.mle-6{
  margin-right: 4rem;
}

.react-datepicker-wrapper{
  width: 100%;
}

.payment-section{
  font-size: 32px;
  /* text-decoration: underline; */
  font-weight: 500;
}
.payment-section-content{
  font-size: 20px !important;
}
.payment-section-price{
  font-size: 36px !important;
  font-weight: 600;
}
.premium{
  text-align: center;
  color: white;
  font-weight: 600;
  border: 1px solid rgba(0, 0, 0, 0.274);
  background: rgb(34,135,136);
  background: linear-gradient(69deg, rgba(34,135,136,1) 0%, rgba(38,96,161,1) 100%);
  border-radius: 10px;
  padding: 8px 16px;
}
.trial{
  text-align: center;
  color: white;
  font-weight: 600;
  border: 1px solid rgba(0, 0, 0, 0.274);
  background: rgb(34,135,136);
  background: linear-gradient(69deg, rgba(34,135,136,1) 0%, rgba(38,96,161,1) 100%);
  border-radius: 10px;
  padding: 8px 16px;
}

/* Watermark Overlay */
.watermark-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; /* Prevent interaction with the overlay */
}

/* Watermark Text */
.watermark {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  color: rgba(0, 0, 0, 0.2);
  font-size: 60px;
  z-index: 1; /* Place the watermark above other content */
}


.element-pixel {
  background: linear-gradient(-135deg, transparent 20px, #3b3873 0); /* Diagonal border effect */
  height: 20px;
  margin: 0; /* Remove any margin */
  position: relative;
}

.element-pixels {
  background: linear-gradient(-135deg, transparent 20px, lightblue 0); /* Diagonal border effect */
  height: 20px;
  margin: 0; /* Remove any margin */
  position: relative;
}

.light-blue {
  
  background-color: #3b3873;
}

.dark-blue {
  background-color: lightblue;
  width: 90%;
}
#square {
  list-style-type: square;
}
.content-area {
  min-height: calc(100vh - 50px);}
  .print-binder {
    background-color: #023047;
    padding: 4px 8px;
    border-radius: 10px;
    color: #fff;
    max-height: 35px;
    z-index: 99;
    margin-right: 8px;
    cursor:pointer;
    min-width : 75px;
}

.cstm-accordian-container {
  min-height: 120px; /* Set a maximum height that is larger than your content */
  max-height: 120px; /* Set a maximum height that is larger than your content */
  transition: max-height 0.3s ease-in-out;
  overflow: hidden;
}
.selectedcolor{
  background-color: #023047 !important;
  color: #ffffff !important;
}
/* 
.plan-boxes:hover .ribbon-top-right {
      transform: scale(1.05);
} */
.ribbon {
  width: 150px;
  float: right;
  /* overflow: hidden; */
}
.ribbon span {
  display: block;
  width: 245px;
  padding: 15px 0;
  background-color: #3498db;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  color: #fff;
  font: 700 14px/1 'Lato', sans-serif;
  text-shadow: 0 1px 1px rgba(0,0,0,.2);
  text-transform: uppercase;
  text-align: center;
}

/* top right*/
.ribbon-top-right {
  top: 115px;
  right: 56px;
}
.ribbon-top-right span {
  left: -25px;
  top: 30px;
  transform: rotate(45deg);
  position: relative;
  z-index: 99;
}

.plan-boxes .titlebx h2, .plan-boxes .titlebx .h2 {
  font-size: 22px;
  color: #023047;
  font-weight: bold;
  -webkit-transform: skew(160deg);
  transform: skew(160deg);
}

.periodic-h{
  width: 20px;
  height: 20px;
}
.cstm-coupon-box{
  width: 400px;
  padding: 24px 8px;
  border: solid 4px #d9eefb;
  border-radius: 12px;
}
.cstm-discount{
  color: rgb(108, 149, 48);
  font-weight: 700;
  margin-top: 8px;
}
.category-btn{
  min-width: 80px;
}
.plan-details{
  color: #fff;
  background: #023047;
  width: 400px;
  padding: 24px 8px;
  border-radius: 12px;
}
.audit-right{
  border-right: solid 1px #f3f3f3;
}

.card-audit{
  background-color: #fafafa !important;
  color: #000 !important;
}
.selected{
  background-color: #e6eaed !important;
  color: #000 !important;
}

.acc{
  background: #f5f5f5;
  padding: 5px;
  border-radius: 5px;
  width: calc(295px - 3rem);
  z-index: 12;
  text-align: center;
  margin-top: 25px;
  margin-bottom: 25px;
  color: red;
  border-width: 1px;
  border: 1px solid red; 
}
@keyframes fadeInAnimation {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
  
}

.selected-answer-content{
  width: fit-content;
background-color: #e0f2f1;
color:#6faca2;
                                       
}

/* Remove spinner arrows from number input */
input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

/* For some versions of Chrome, Safari, and Edge */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For modern versions of Chrome, Safari, and Edge */
input[type="number"] {
  -webkit-appearance: textfield;
}
ul.ans-list {
  list-style-type: none;
}

.owner-list{
  display: flex;
  white-space: nowrap;
  margin-top: 5px;
}

.mssg-txt{
  min-height: 250px !important;
}
.disable {
  /* Note: pointer-events not supported by IE10 and under */
  pointer-events: none;
  opacity: 0.4;
}

.periodic.nav-tabs .nav-link.active{

  color:#4fa5d8
}

.input-mfa{
  border:1px solid black;
}

@media(min-width: 778px){
   
  .chatIcon{
 display: none;
  }
 
}
@media(max-width: 778px){
   
  .viewIcons{
 display: none;
  }
 
}
@media (max-width: 920px) {
  .manage-access-box{
    font-size: 13px;
  }
  .permission-icon{
    font-size: 13px;
  }
  .permission-user{
    font-size: 13px;
    font-weight: bold;
  }
  .new-tag{
    font-size: 13px;
  }
  .profile-content h5{
    font-size: 13px;
  }
  .viewIcon{
    display: none ;
  }
  
}

.custom-dropdown-button .btn-primary.dropdown-toggle {
  color: black !important;
  background-color: white !important;
  border-color: black !important;
}